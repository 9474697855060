/*--------------------------------------
/*  Brand Css Start
----------------------------------------*/

.single-brand {
  text-align: center;
  .thumb {
    display: block;
  }
}

/*--------------------------------------
/*  Brand Css ENd
----------------------------------------*/
